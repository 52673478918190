import React from 'react'
import { View, Image, ImageBackground, Text } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Layout, Colors, TextStyles, Icons } from '../constants'

export default class ContentImage extends React.Component {
    render() {
        return (
            <View
                style={[
                    Layout.projectContainer,
                    {
                        backgroundColor: this.props.backgroundColor
                            ? this.props.backgroundColor
                            : Colors.aesop,
                        paddingHorizontal: Layout.paddingLarge,
                    },
                ]}
            >
                <View
                    style={{
                        alignSelf: this.props.align,
                        alignItems: 'center',
                    }}
                >
                    <Image
                        style={{
                            overflow: 'hidden',
                            //borderRadius: Layout.borderRadiusXL,
                            height: Layout.SCREEN_HEIGHT * 0.5,
                            width: Layout.isMediumDevice
                                ? Layout.SCREEN_WIDTH
                                : Layout.SCREEN_WIDTH * 0.382,

                            // width: Layout.isMediumDevice
                            //     ? Layout.SCREEN_WIDTH - Layout.paddingXL
                            //     : Layout.SCREEN_HEIGHT * 0.382,
                            // height: Layout.isMediumDevice
                            //     ? Layout.SCREEN_HEIGHT * 0.8
                            //     : Layout.SCREEN_HEIGHT * 0.618,
                        }}
                        source={this.props.uri}
                        resizeMode={'contain'}
                    />
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                padding: Layout.paddingMedium,
                                paddingBottom: Layout.paddingXL,
                                textAlign: 'center',
                            },
                        ]}
                    >
                        {this.props.title}
                    </Text>
                </View>
            </View>
        )
    }
}
