import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Layout, Buttons, Colors, TextStyles, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import { VenueProfileConsumer } from '../../context/VenueProfileContext'
import { VenueStoriesConsumer } from '../../context/VenueStoriesContext'
import { WindowResizeConsumer } from '../../context/WindowResizeContext'
import imageData from '../../data/imageData'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import VenueRecentPostList from '../../components/VenueRecentPostList'
import RequestInfoComponent from '../../components/RequestInfo'
import ImageCarousel from '../../components/ImageCarousel'
import ContentImage from '../../components/ContentImage'
import ProductExplorerWebApp from '../../components/ProductExplorerWebApp'

export default class AuthHomeScreen extends React.Component {
    state = {
        stickyIndex: null,
        stickyLocationOne: null,
        unStickyLocationOne: null,
        stickyLocationTwo: null,
        unStickyLocationTwo: null,
        opacity: 1,
    }

    componentDidMount = async () => {
        this.clearUrlToken()
        await this.props.navigation.setParams({
            active: false,
            //transparentHeader: true,
        })

        const placeId = 'ChIJOWvrIUuBhYARMEk3vdJP_ic' /* Indvstry */

        await this.venueProfileContext.initializeVenueProfile(placeId)
        await this.venueStoriesContext.setVenuePlaceId(placeId)

        await this.venueStoriesContext.venueRecentEventPostRemoteRequest(
            placeId
        )

        this.props.navigation.setParams({
            indvstryUrl: this.venueProfileContext.indvstryUrl,
            // active: false,
            // transparentHeader: true,
        })

        this.clearUrlToken()
    }

    clearUrlToken = () => {
        const { origin, pathname } = window.location
        window.history.replaceState(
            history.state,
            window.document.title,
            `${origin}${pathname}`
        )
    }

    setStickyLocationTwo = async (event) => {
        const pageLocation = await Math.floor(event.nativeEvent.layout.y)

        this.setState({ stickyLocationTwo: pageLocation })
    }

    setUnStickyLocationTwo = async (event) => {
        const pageLocation = await Math.floor(event.nativeEvent.layout.y)

        this.setState({ unStickyLocationTwo: pageLocation })
    }

    onScrollSticky = async (event) => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (
            pageYOffset >= this.state.stickyLocationOne &&
            pageYOffset <= this.state.unStickyLocationOne
        ) {
            this.setState({ stickyIndex: 6 })
        } else if (
            pageYOffset >= this.state.stickyLocationTwo &&
            pageYOffset <= this.state.unStickyLocationTwo
        ) {
            this.setState({ stickyIndex: 1 })
        } else {
            this.setState({ stickyIndex: null })
        }
    }

    onPressExplore = async ({ navigation }) => {
        this.scroll.scrollTo({
            y: Layout.isMediumDevice
                ? Layout.SCREEN_HEIGHT * 1.8
                : Layout.SCREEN_HEIGHT,
        })
    }

    onPressJoin = async ({ navigation }) => {
        this.scroll.scrollToEnd()
    }

    onPressContact = async ({ navigation }) => {
        {
            this.props.navigation.navigate('Contact')
        }
    }

    // onPressAbout = async ({ navigation }) => {
    //     {
    //         this.props.navigation.navigate('VenueProfile', {
    //             indvstryUrl: this.venueProfileContext.indvstryUrl,
    //             placeId: 'ChIJOWvrIUuBhYARMEk3vdJP_ic',
    //         })
    //     }
    // }

    onScroll = async (event) => {
        const pageYOffset = await event.nativeEvent.contentOffset.y
        const topNavHeight = Layout.isMediumDevice
            ? Layout.topNavHeightMobile
            : Layout.topNavHeightDesktop

        if (pageYOffset >= topNavHeight) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }

        //this.clearUrlToken()
    }

    renderSeparator() {
        return (
            <View
                style={{
                    //flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                }}
            />
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {(windowContext) => (
                    <View
                        style={{ flex: 1 }}
                        ref={(ref) => {
                            this.windowContext = windowContext
                        }}
                    >
                        <VenueStoriesConsumer>
                            {(venueStoriesContext) => (
                                <View
                                    style={{ flex: 1 }}
                                    ref={(ref) => {
                                        this.venueStoriesContext = venueStoriesContext
                                    }}
                                >
                                    <VenueProfileConsumer>
                                        {(venueProfileContext) => (
                                            <View
                                                style={{ flex: 1 }}
                                                ref={(ref) => {
                                                    this.venueProfileContext = venueProfileContext
                                                }}
                                            >
                                                <UserProfileConsumer>
                                                    {(userProfileContext) => {
                                                        return (
                                                            <View
                                                                style={{
                                                                    flex: 1,
                                                                }}
                                                                ref={(ref) => {
                                                                    this.userProfileContext = userProfileContext
                                                                }}
                                                            >
                                                                <ScrollView
                                                                    style={{
                                                                        flex: 1,
                                                                        backgroundColor:
                                                                            Colors.aesop,
                                                                    }}
                                                                    ref={(
                                                                        node
                                                                    ) =>
                                                                        (this.scroll = node)
                                                                    }
                                                                    onScroll={(
                                                                        event
                                                                    ) =>
                                                                        Layout.isMediumDevice
                                                                            ? this.onScroll(
                                                                                  event
                                                                              )
                                                                            : this.onScroll(
                                                                                  event
                                                                              ) &&
                                                                              this.onScrollSticky(
                                                                                  event
                                                                              )
                                                                    }
                                                                    scrollEventThrottle={
                                                                        16
                                                                    }
                                                                    stickyHeaderIndices={[
                                                                        this
                                                                            .state
                                                                            .stickyIndex,
                                                                    ]}
                                                                    decelerationRate={
                                                                        'fast'
                                                                    }
                                                                >
                                                                    <LinearGradient
                                                                        start={[
                                                                            0.0,
                                                                            0.0,
                                                                        ]}
                                                                        end={[
                                                                            1.0,
                                                                            1.0,
                                                                        ]}
                                                                        colors={[
                                                                            Colors.aesop,
                                                                            Colors.sunshine,
                                                                        ]}
                                                                        style={[
                                                                            Layout.projectContainer,
                                                                            {
                                                                                backgroundColor:
                                                                                    Colors.tintColor,
                                                                                paddingTop:
                                                                                    Layout.paddingXL,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <View
                                                                            style={
                                                                                Layout.columnContainer
                                                                            }
                                                                        >
                                                                            <View
                                                                                style={[
                                                                                    Layout.columnTwo,
                                                                                    {
                                                                                        alignItems:
                                                                                            'center',
                                                                                        // alignItems: Layout.isMediumDevice
                                                                                        //     ? 'center'
                                                                                        //     : 'flex-start',
                                                                                        justifyContent:
                                                                                            'center',
                                                                                        // backgroundColor:
                                                                                        //     'white',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Heading0,
                                                                                        {
                                                                                            paddingTop: Layout.isMediumDevice
                                                                                                ? Layout.paddingLarge
                                                                                                : 0,
                                                                                            paddingBottom: Layout.isMediumDevice
                                                                                                ? Layout.paddingLarge
                                                                                                : Layout.paddingXL,
                                                                                            textAlign:
                                                                                                'center',
                                                                                            // textAlign: Layout.isMediumDevice
                                                                                            //     ? 'center'
                                                                                            //     : 'left',
                                                                                            maxWidth: 480,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    Culinary
                                                                                    events
                                                                                    and
                                                                                    inspiring
                                                                                    stories,
                                                                                    all
                                                                                    in
                                                                                    one
                                                                                    place.
                                                                                </Text>

                                                                                <Button
                                                                                    onPress={
                                                                                        this
                                                                                            .onPressJoin
                                                                                    }
                                                                                    buttonStyle={[
                                                                                        Buttons.button,
                                                                                        {
                                                                                            minWidth: 160,
                                                                                            marginBottom:
                                                                                                Layout.paddingLarge,
                                                                                        },
                                                                                    ]}
                                                                                    title={
                                                                                        'Get Foodist Stories'
                                                                                    }
                                                                                />

                                                                                {/* <Button
                                                                                    iconRight={
                                                                                        true
                                                                                    }
                                                                                    iconName={
                                                                                        'chevrons-down'
                                                                                    }
                                                                                    iconColor={
                                                                                        Colors.tintColor
                                                                                    }
                                                                                    onPress={
                                                                                        this
                                                                                            .onPressExplore
                                                                                    }
                                                                                    buttonStyle={[
                                                                                        Buttons.buttonUnderline,
                                                                                        {
                                                                                            paddingHorizontal: 0,
                                                                                        },
                                                                                    ]}
                                                                                    textColor={
                                                                                        Colors.tintColor
                                                                                    }
                                                                                    title={
                                                                                        'Learn more'
                                                                                    }
                                                                                /> */}
                                                                            </View>

                                                                            <View
                                                                                style={[
                                                                                    Layout.columnTwo,
                                                                                    {
                                                                                        alignItems:
                                                                                            'center',
                                                                                        justifyContent:
                                                                                            'center',

                                                                                        // backgroundColor:
                                                                                        //     Colors.accentDeepsea,
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <VenueRecentPostList
                                                                                    navigation={
                                                                                        this
                                                                                            .props
                                                                                            .navigation
                                                                                    }
                                                                                />
                                                                            </View>
                                                                        </View>
                                                                    </LinearGradient>

                                                                    {/* <ImageCarousel
                                                                        title={
                                                                            'San Francisco'
                                                                        }
                                                                        imageData={
                                                                            imageData.neighborhoods
                                                                        }
                                                                        navigation={
                                                                            this
                                                                                .props
                                                                                .navigation
                                                                        }
                                                                    /> */}

                                                                    <View
                                                                        style={[
                                                                            Layout.sectionContainer,
                                                                            {
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                            },
                                                                        ]}
                                                                        onLayout={(
                                                                            event
                                                                        ) =>
                                                                            this.setStickyLocationTwo(
                                                                                event
                                                                            )
                                                                        }
                                                                    >
                                                                        <View
                                                                            style={
                                                                                Layout.columnOne
                                                                            }
                                                                        >
                                                                            <Text
                                                                                style={[
                                                                                    TextStyles.Heading1,
                                                                                    {
                                                                                        textAlign:
                                                                                            'center',
                                                                                        maxWidth: 720,
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                For
                                                                                food
                                                                                lovers
                                                                                and
                                                                                purveyors
                                                                                of
                                                                                taste
                                                                            </Text>
                                                                        </View>
                                                                    </View>
                                                                    <ContentImage
                                                                        uri={require('../../assets/images/simona-todorova-1HWPl4G5EE0-unsplash.jpg')}
                                                                        align={
                                                                            'flex-end'
                                                                        }
                                                                        title={
                                                                            'Tasting experiences'
                                                                        }
                                                                    />
                                                                    <ContentImage
                                                                        uri={require('../../assets/images/facundo-ruiz-rERuRyBjzgU-unsplash.jpg')}
                                                                        align={
                                                                            'flex-start'
                                                                        }
                                                                        title={
                                                                            'Pop-up dining'
                                                                        }
                                                                    />
                                                                    <ContentImage
                                                                        uri={require('../../assets/images/shangyou-shi-VdRex9AiBOc-unsplash.jpg')}
                                                                        align={
                                                                            'center'
                                                                        }
                                                                        title={
                                                                            'Behind-the-scenes'
                                                                        }
                                                                    />
                                                                    <ContentImage
                                                                        uri={require('../../assets/images/louis-hansel-7_epF0UqeEs-unsplash.jpg')}
                                                                        align={
                                                                            'flex-start'
                                                                        }
                                                                        title={
                                                                            'Exploratory menus'
                                                                        }
                                                                    />
                                                                    <ContentImage
                                                                        uri={require('../../assets/images/jeff-siepman-kyuPjZudBKs-unsplash.jpg')}
                                                                        align={
                                                                            'flex-end'
                                                                        }
                                                                        title={
                                                                            'Spotlight team members'
                                                                        }
                                                                    />
                                                                    <ContentImage
                                                                        uri={require('../../assets/images/annie-spratt-Iqwnr00mfQM-unsplash.jpg')}
                                                                        align={
                                                                            'center'
                                                                        }
                                                                        title={
                                                                            'Farm-to-table stories'
                                                                        }
                                                                    />
                                                                    <View
                                                                        onLayout={(
                                                                            event
                                                                        ) =>
                                                                            this.setUnStickyLocationTwo(
                                                                                event
                                                                            )
                                                                        }
                                                                        // style={[
                                                                        //     Layout.sectionFooterContainer,
                                                                        //     {
                                                                        //         backgroundColor:
                                                                        //             Colors.aesop,
                                                                        //     },
                                                                        // ]}

                                                                        style={[
                                                                            Layout.sectionHeaderContainer,
                                                                            {
                                                                                minHeight: Layout.isMediumDevice
                                                                                    ? 0
                                                                                    : 480,
                                                                                height: Layout.isMediumDevice
                                                                                    ? 0
                                                                                    : Layout.SCREEN_HEIGHT *
                                                                                      0.618,
                                                                                backgroundColor:
                                                                                    Colors.aesop,
                                                                            },
                                                                        ]}
                                                                    />

                                                                    <ProductExplorerWebApp
                                                                        onPress={
                                                                            this
                                                                                .onPressJoin
                                                                        }
                                                                    />

                                                                    {/* <View
                                                                        style={[
                                                                            Layout.sectionHeaderContainer,
                                                                            {
                                                                                backgroundColor:
                                                                                    Colors.aesop,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <View
                                                                            style={[
                                                                                Layout.columnOne,
                                                                                {
                                                                                    maxWidth: Layout.isTabletDevice
                                                                                        ? 480
                                                                                        : 720,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Text
                                                                                style={[
                                                                                    TextStyles.Heading1,
                                                                                    {
                                                                                        textAlign:
                                                                                            'center',
                                                                                        paddingTop:
                                                                                            Layout.paddingXL,

                                                                                        paddingBottom:
                                                                                            Layout.paddingMedium,
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                For
                                                                                food
                                                                                lovers
                                                                                and
                                                                                purveyors
                                                                                of
                                                                                taste.
                                                                            </Text>
                                                                            <Text
                                                                                style={[
                                                                                    TextStyles.Paragraph,
                                                                                    {
                                                                                        textAlign:
                                                                                            'center',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                Indvstry
                                                                                is
                                                                                here
                                                                                to
                                                                                help
                                                                                you
                                                                                discover
                                                                                exploratory
                                                                                food
                                                                                and
                                                                                drink
                                                                                menus
                                                                                from
                                                                                your
                                                                                favorite
                                                                                restaurants
                                                                                and
                                                                                join
                                                                                culinary
                                                                                experiences.
                                                                            </Text>
                                                                            <Button
                                                                                onPress={
                                                                                    this
                                                                                        .onPressContact
                                                                                }
                                                                                textColor={
                                                                                    Colors.tintColor
                                                                                }
                                                                                buttonStyle={[
                                                                                    Buttons.buttonMini,
                                                                                    {
                                                                                        alignSelf:
                                                                                            'center',
                                                                                        marginVertical:
                                                                                            Layout.paddingLarge,
                                                                                    },
                                                                                ]}
                                                                                title={
                                                                                    'Contact us'
                                                                                }
                                                                            />
                                                                        </View>
                                                                    </View> */}
                                                                    <View
                                                                        style={[
                                                                            Layout.sectionHeaderContainer,
                                                                            {
                                                                                backgroundColor:
                                                                                    Colors.white,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <View
                                                                            style={
                                                                                Layout.columnContainer
                                                                            }
                                                                        >
                                                                            <View
                                                                                style={[
                                                                                    Layout.columnTwo,
                                                                                    {
                                                                                        justifyContent:
                                                                                            'center',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Heading1,
                                                                                        {
                                                                                            paddingBottom:
                                                                                                Layout.paddingSmall,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    Get
                                                                                    Foodist
                                                                                    Stories
                                                                                </Text>
                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Paragraph,
                                                                                        {
                                                                                            paddingBottom: Layout.isMediumDevice
                                                                                                ? 0
                                                                                                : Layout.paddingLarge,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    Enter
                                                                                    your
                                                                                    email
                                                                                    to
                                                                                    receive
                                                                                    the
                                                                                    latest
                                                                                    updates
                                                                                    from
                                                                                    Foodist
                                                                                    Direct.
                                                                                </Text>
                                                                            </View>
                                                                            <View
                                                                                style={[
                                                                                    Layout.columnTwo,
                                                                                    {
                                                                                        justifyContent:
                                                                                            'center',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <RequestInfoComponent
                                                                                    buttonIcon={
                                                                                        true
                                                                                    }
                                                                                    placeId={
                                                                                        'ChIJOWvrIUuBhYARMEk3vdJP_ic'
                                                                                    }
                                                                                />
                                                                            </View>
                                                                        </View>
                                                                    </View>
                                                                    <Footer
                                                                        navigation={
                                                                            this
                                                                                .props
                                                                                .navigation
                                                                        }
                                                                    />
                                                                </ScrollView>
                                                            </View>
                                                        )
                                                    }}
                                                </UserProfileConsumer>
                                            </View>
                                        )}
                                    </VenueProfileConsumer>
                                </View>
                            )}
                        </VenueStoriesConsumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
