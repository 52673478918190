import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { createStackNavigator } from 'react-navigation-stack'
import { DrawerActions, createDrawerNavigator } from 'react-navigation-drawer'
import { Layout, Buttons, Colors, TextStyles } from '../constants'
import AuthHomeScreen from '../screens/auth/AuthHomeScreen'
import ContactScreen from '../screens/global/ContactScreen'
import RestaurantsScreen from '../screens/global/RestaurantsScreen'
import RequestInfoScreen from '../screens/global/RequestInfoScreen'
import RequestConfirmationScreen from '../screens/global/RequestConfirmationScreen'
import DrawerNavigation from '../components/DrawerNavigation'
import ButtonIcon from '../components/ButtonIcon'
import VenueProfile from '../screens/venue/VenueProfileScreen'
import VenueStories from '../screens/venue/VenueStoriesScreen'
import TermsOfUse from '../screens/global/TermsOfUseScreen'
import PrivacyPolicy from '../screens/global/PrivacyPolicyScreen'

const DrawerConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/drawer-navigator.html
     *
     **/
    drawerWidth: Layout.SCREEN_WIDTH,
    // drawerWidth: Layout.isTabletDevice
    //     ? Layout.SCREEN_WIDTH
    //     : Layout.SCREEN_WIDTH * 0.5,
    drawerPosition: 'left',
    drawerBackgroundColor: Colors.tintColor,
    overlayColor: Colors.darkBackground,
    drawerLockMode: 'locked-closed',

    contentComponent: (props) => <DrawerNavigation {...props} />,
}

const StackConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/stack-navigator.html#stacknavigatorconfig
     *
     **/
    //initialRouteName: 'AuthHome',
    lazy: true /* defaults to true */,
    unmountInactiveScreens: false /* defaults to false */,
    headerMode: 'screen',
    initialRouteName: 'AuthHome',

    defaultNavigationOptions: ({ navigation }) => ({
        cardShadowEnabled: false,
        headerTitleAlign: 'center',
        headerBackTitle: null,
        headerPressColorAndroid: Colors.tintColor,
        headerTransparent: true,
        headerStyle: {
            boxShadow: 'none', // for web
            //elevation: 0, //for android
            shadowOpacity: 0, //for ios
            borderBottomWidth: 0, //for ios
            paddingVertical: Layout.paddingSmall,
            height: Layout.isTabletDevice
                ? Layout.topNavHeightMobile
                : Layout.topNavHeightDesktop,
            // backgroundColor: navigation.getParam('active')
            //     ? 'transparent'
            //     : navigation.getParam('transparentHeader')
            //     ? 'transparent'
            //     : Colors.tintColor,
            backgroundColor: navigation.getParam('active')
                ? Colors.tintColor // was transparent
                : 'transparent',
        },

        headerLeft: (
            <View
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    paddingLeft: Layout.isTabletDevice
                        ? Layout.paddingSmall
                        : Layout.paddingLarge,
                }}
            >
                {Layout.isTabletDevice ? (
                    <ButtonIcon
                        onPress={() =>
                            navigation.dispatch(DrawerActions.toggleDrawer())
                        }
                        buttonStyle={Buttons.menuButtonTransparentBG}
                        iconName={'menu'}
                        iconColor={
                            navigation.getParam('active')
                                ? Colors.white
                                : Colors.tintColor
                        }
                    />
                ) : (
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    //paddingRight: Layout.paddingLarge,
                                    paddingVertical: Layout.paddingMedium,
                                    // color: navigation.getParam('active')
                                    //     ? Colors.inverseText
                                    //     : Colors.tintColor,

                                    color: navigation.getParam('active')
                                        ? Colors.white
                                        : Colors.tintColor,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => {
                                // navigation.navigate('VenueProfile', {
                                //     indvstryUrl: ':indvstryUrl',
                                //     active: false,
                                //     //placeId: 'ChIJWXgajx-BhYAR7LnSp9kfSzg', /* Family Cafe */
                                //     //placeId:
                                //     //navigation.getParam('placeId'),
                                //     scrollNeighorhood: true
                                // })
                                // navigation.navigate('VenueProfile', {
                                //     indvstryUrl: ':indvstryUrl',
                                //     active: false,
                                //     //placeId: 'ChIJWXgajx-BhYAR7LnSp9kfSzg', /* Family Cafe */
                                //     placeId:
                                //         'ChIJOWvrIUuBhYARMEk3vdJP_ic' /* Indvstry */,
                                //     //navigation.getParam('placeId'),
                                //     //scrollNeighorhood: true,
                                // })
                                // if (
                                //     navigation.getParam('route') === 'AuthHome'
                                // ) {
                                //     console.log(
                                //         'TRYING TO GET ROUTENAME',
                                //         navigation.getParam('route')
                                //     )
                                // }
                                // navigation.navigate('AuthHome').then(
                                //     this.scroll.scrollTo({
                                //         y: Layout.SCREEN_HEIGHT,
                                //     })
                                // )

                                navigation.navigate('Restaurants')

                                const { origin, pathname } = window.location
                                window.history.replaceState(
                                    history.state,
                                    window.document.title,
                                    `${origin}${pathname}`
                                )

                                // window.location.scrollTo({
                                //     y: Layout.SCREEN_HEIGHT,
                                // })
                            }}
                        >
                            Restaurants
                        </Text>
                        {/* <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    paddingVertical: Layout.paddingMedium,
                                    color: navigation.getParam('active', false)
                                        ? Colors.inverseText
                                        : Colors.tintColor,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => navigation.navigate('About')}
                        >
                            About
                        </Text> */}
                    </View>
                )}
            </View>
        ),

        headerTitle: (
            <View style={{ flex: 1 }}>
                <TouchableOpacity
                    style={{
                        alignSelf: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onPress={() => {
                        navigation.navigate('AuthHome')

                        const { origin, pathname } = window.location
                        window.history.replaceState(
                            history.state,
                            window.document.title,
                            `${origin}${pathname}`
                        )
                    }}
                >
                    <Text
                        numberOfLines={1}
                        style={[
                            TextStyles.Logotype,
                            {
                                // color: navigation.getParam('active')
                                //     ? Colors.inverseText
                                //     : Colors.tintColor,
                                color: navigation.getParam('active')
                                    ? Colors.white
                                    : Colors.tintColor,
                                textAlign: 'center',
                            },
                        ]}
                    >
                        Foodist
                    </Text>
                </TouchableOpacity>
            </View>
        ),

        headerRight: (
            <View
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    paddingRight: Layout.isTabletDevice
                        ? Layout.paddingSmall
                        : Layout.paddingLarge,
                }}
            >
                {Layout.isTabletDevice ? (
                    <ButtonIcon
                        onPress={() => navigation.navigate('RequestInfo')}
                        iconName={'plus'}
                        buttonStyle={Buttons.menuButtonTransparentBG}
                        // iconColor={
                        //     navigation.getParam('active')
                        //         ? Colors.white
                        //         : Colors.tintColor

                        // }
                        iconColor={
                            navigation.getParam('active')
                                ? Colors.white
                                : Colors.tintColor
                        }
                    />
                ) : (
                    // <ButtonIcon
                    //     onPress={() => navigation.navigate('LogIn')}
                    //     iconName={'user'}
                    //     buttonStyle={Buttons.menuButtonTransparentBG}
                    //     iconColor={
                    //         navigation.getParam('active', false)
                    //             ? Colors.white
                    //             : Colors.tintColor
                    //     }
                    // />
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    //paddingRight: Layout.paddingLarge,
                                    paddingVertical: Layout.paddingMedium,
                                    // color: navigation.getParam('active')
                                    //     ? Colors.inverseText
                                    //     : Colors.tintColor,
                                    color: navigation.getParam('active')
                                        ? Colors.white
                                        : Colors.tintColor,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => {
                                navigation.navigate('Contact')
                                const { origin, pathname } = window.location
                                window.history.replaceState(
                                    history.state,
                                    window.document.title,
                                    `${origin}${pathname}`
                                )
                            }}
                        >
                            Contact
                        </Text>
                        {/* <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    paddingVertical: Layout.paddingMedium,
                                    color: navigation.getParam('active', false)
                                        ? Colors.inverseText
                                        : Colors.tintColor,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => {
                                navigation.navigate('LogIn')
                            }}
                        >
                            Log in
                        </Text> */}
                    </View>
                )}
            </View>
        ),
    }),
}

const PublicStack = createStackNavigator(
    {
        AuthHome: {
            screen: AuthHomeScreen,
            path: null,
            //params: { active: false},
        },

        Contact: {
            screen: ContactScreen,
            path: 'contact',
            //params: { active: false },
        },

        Restaurants: {
            screen: RestaurantsScreen,
            path: 'restaurant',
            params: { active: true },
        },

        /* TODO: Do deep linking properly: https://reactnavigation.org/docs/en/deep-linking.html */
        IndvstryProfile: {
            screen: VenueProfile,
            path: 'studio',
            params: {
                //active: true,
                //active: false,
                //transparentHeader: true,
                placeId: 'ChIJOWvrIUuBhYARMEk3vdJP_ic',
                heroImage: require('../assets/images/spencer-davis-ps8zSj_hqjU-unsplash.jpg'),
            },
        },

        FamilyProfile: {
            screen: VenueProfile,
            path: 'family-cafe',
            params: {
                //active: true,
                placeId: 'ChIJWXgajx-BhYAR7LnSp9kfSzg',
                heroImage: require('../assets/images/slider_one.jpg'),
            },
        },

        CentralProfile: {
            screen: VenueProfile,
            path: 'central-kitchen',
            params: {
                //active: true,
                placeId: 'ChIJr-EMFjd-j4ARHKaF3ehlmgY',
                heroImage: require('../assets/images/shangyou-shi-VdRex9AiBOc-unsplash.jpg'),
            },
        },

        CrennProfile: {
            screen: VenueProfile,
            path: 'bar-crenn',
            params: {
                //active: true,
                placeId: 'ChIJL66ohdCAhYARO3o0IhzIc8U',
                heroImage: require('../assets/images/sasha-stories-XVTTK3wcYME-unsplash.jpg'),
            },
        },

        GreenleafVeniceProfile: {
            screen: VenueProfile,
            path: 'greenleaf-venice',
            params: {
                placeId: 'ChIJgeJYqMC6woARjpA006N89r8',
                heroImage: require('../assets/images/sasha-stories-XVTTK3wcYME-unsplash.jpg'),
            },
        },

        VenueStories: {
            screen: VenueStories,
            path: 'event',
            //params: { active: true, transparentHeader: false },
            navigationOptions: ({ navigation }) => ({
                headerTitle: null,
                headerLeft: null,

                headerRight: (
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: Layout.paddingMedium,
                            paddingHorizontal: Layout.isTabletDevice
                                ? Layout.paddingSmall
                                : Layout.paddingMedium,
                        }}
                    >
                        <ButtonIcon
                            onPress={() => navigation.goBack()}
                            iconName={'x'}
                            //iconColor={Colors.tintColor}
                            buttonStyle={Buttons.menuButton}
                        />
                    </View>
                ),
            }),
        },

        RequestInfo: {
            screen: RequestInfoScreen,
            path: 'signup',
        },

        RequestConfirmation: {
            screen: RequestConfirmationScreen,
        },

        Terms: {
            screen: TermsOfUse,
            path: 'terms',
        },

        Privacy: {
            screen: PrivacyPolicy,
            path: 'privacy',
        },
    },
    StackConfig
)

PublicStack.path = ''

const drawerNavigator = createDrawerNavigator(
    {
        PublicStack,
    },
    DrawerConfig
)

drawerNavigator.path = ''

export default drawerNavigator
