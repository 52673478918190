import Layout from './Layout'
import Colors from './Colors'

const regular = 'neuzeit-s'
const bold = 'neuzeit-s-bold'
const serifRegular = 'mattone-regular'
const serifBold = 'coconat-bold'

const LogotypeSize = Layout.isMediumDevice ? 21 : 24

const Heading0LineHeight = Layout.isMediumDevice ? 56 : 72
const Heading0FontSize = Layout.isMediumDevice ? 48 : 64

const Heading1LineHeight = Layout.isMediumDevice ? 44 : 52
const Heading1FontSize = Layout.isMediumDevice ? 32 : 42

const Heading2LineHeight = Layout.isMediumDevice ? 44 : 50
const Heading2FontSize = Layout.isMediumDevice ? 28 : 34

const Heading3LineHeight = 38
const Heading3FontSize = Layout.isMediumDevice ? 23 : 24

const SubtitleLineHeight = 42
const ParagraphLineHeight = 34
const ListParagraphLineHeight = 24
const CaptionLineHeight = 28

export default {
    ListParagraphLineHeight,

    Logotype: {
        fontFamily: 'mattone-regular',
        fontSize: LogotypeSize,
        color: Colors.primaryText,
        letterSpacing: -0.5,
        textTransform: 'uppercase',
    },
    Heading0: {
        fontFamily: serifRegular,
        fontSize: Heading0FontSize,
        letterSpacing: -1.5,
        color: Colors.primaryText,
        lineHeight: Heading0LineHeight,
        //textTransform: 'uppercase',
    },
    Heading1: {
        fontFamily: serifRegular,
        fontSize: Heading1FontSize,
        letterSpacing: -1,
        color: Colors.primaryText,
        lineHeight: Heading1LineHeight,
    },
    Heading2: {
        fontFamily: regular,
        fontSize: Heading2FontSize,
        letterSpacing: -0.5,
        color: Colors.primaryText,
        lineHeight: Heading2LineHeight,
    },
    Heading3: {
        fontFamily: bold,
        fontSize: Heading3FontSize,
        color: Colors.primaryText,
        letterSpacing: -0.25,
        lineHeight: Heading3LineHeight,
    },

    Subtitle: {
        fontFamily: serifRegular,
        fontSize: 24,
        color: Colors.primaryText,
        lineHeight: SubtitleLineHeight,
    },
    Paragraph: {
        fontFamily: regular,
        fontSize: 20,
        color: Colors.primaryText,
        lineHeight: ParagraphLineHeight,
    },

    ParagraphBold: {
        fontFamily: bold,
        fontSize: 20,
        color: Colors.primaryText,
        lineHeight: ParagraphLineHeight,
    },

    Caption: {
        fontFamily: regular,
        fontSize: 16,
        color: Colors.secondaryText,
        letterSpacing: 0.5,
        lineHeight: CaptionLineHeight,
    },

    Overline: {
        fontFamily: bold,
        fontSize: 13,
        color: Colors.primaryText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },

    OverlineLight: {
        fontFamily: regular,
        fontSize: 14,
        color: Colors.primaryText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },

    Button: {
        fontFamily: regular,
        fontSize: 18, //was 16
        letterSpacing: 0.25,
        color: Colors.primaryText,
    },

    // Inverse text

    LogotypeInverse: {
        fontFamily: regular,
        fontSize: LogotypeSize,
        color: Colors.inverseText,
        letterSpacing: -0.15,
    },

    Heading0Inverse: {
        fontFamily: serifRegular,
        fontSize: Heading0FontSize,
        letterSpacing: -1.5,
        color: Colors.inverseText,
        lineHeight: Heading0LineHeight,
        //textTransform: 'uppercase',
    },

    Heading1Inverse: {
        fontFamily: serifRegular,
        fontSize: Heading1FontSize,
        letterSpacing: -1,
        color: Colors.inverseText,
        lineHeight: Heading1LineHeight,
    },

    Heading2Inverse: {
        fontFamily: regular,
        fontSize: Heading2FontSize,
        letterSpacing: -0.5,
        color: Colors.inverseText,
        lineHeight: Heading2LineHeight,
    },

    Heading3Inverse: {
        fontFamily: bold,
        fontSize: Heading3FontSize,
        color: Colors.inverseText,
        letterSpacing: -0.25,
        lineHeight: Heading3LineHeight,
    },

    ParagraphInverse: {
        fontFamily: regular,
        fontSize: 16,
        color: Colors.inverseText,
        textTransform: 'uppercase',
    },

    ParagraphBoldInverse: {
        fontFamily: bold,
        fontSize: 16,
        color: Colors.inverseText,
        textTransform: 'uppercase',
    },

    SubtitleInverse: {
        fontFamily: serifRegular,
        fontSize: 24,
        color: Colors.inverseText,
        lineHeight: SubtitleLineHeight,
    },
    ParagraphInverse: {
        fontFamily: regular,
        fontSize: 20,
        color: Colors.inverseText,
        lineHeight: ParagraphLineHeight,
    },
    CaptionInverse: {
        fontFamily: regular,
        fontSize: 16,
        color: Colors.inverseText,
        letterSpacing: 0.5,
        lineHeight: CaptionLineHeight,
    },
    OverlineInverse: {
        fontFamily: bold,
        fontSize: 13,
        color: Colors.inverseText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },
    OverlineLightInverse: {
        fontFamily: regular,
        fontSize: 14,
        color: Colors.inverseText,
        letterSpacing: 2.5,
        textTransform: 'uppercase',
        lineHeight: CaptionLineHeight,
    },
}
