import React from 'react'
import { ScrollView, View, Text, ImageBackground } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Linking } from 'expo'
import { Colors, Buttons, TextStyles, Layout, Icons } from '../../constants'
import Button from '../../components/Button'
import ButtonIcon from '../../components/ButtonIcon'
import Footer from '../../components/Footer'

export default class Restaurants extends React.Component {
    componentDidMount = async () => {
        await this.props.navigation.setParams({
            active: true,
        })
    }

    onPressScroll = async ({ navigation }) => {
        this.scroll.scrollTo(Layout.SCREEN_HEIGHT)
    }

    render() {
        return (
            <View
                style={{
                    flex: 1,
                }}
            >
                <ScrollView
                    style={{ flex: 1 }}
                    ref={(node) => (this.scroll = node)}
                >
                    <View
                        style={[
                            Layout.projectContainer,
                            { backgroundColor: Colors.tintColor },
                        ]}
                    >
                        <View style={Layout.columnOne}>
                            <Text
                                style={[
                                    TextStyles.Heading1Inverse,
                                    {
                                        textAlign: 'center',
                                        maxWidth: 720,
                                        //paddingBottom: Layout.paddingXL,
                                    },
                                ]}
                            >
                                If you'd like to support America's restaurants
                                in our current moment, here are some trusted
                                organizations you can help out through funding
                                or advocacy.
                            </Text>
                            <View
                                style={{
                                    display: Layout.isMediumDevice
                                        ? 'none'
                                        : 'flex',
                                    position: 'absolute',
                                    bottom: 0,
                                }}
                            >
                                <ButtonIcon
                                    iconName={'chevron-down'}
                                    iconSize={Icons.medium}
                                    iconColor={Colors.tintColor}
                                    buttonStyle={[
                                        Buttons.menuButtonTransparentBG,
                                        {
                                            backgroundColor: Colors.white,
                                            alignSelf: 'flex-start',
                                        },
                                    ]}
                                    navigation={this.props.navigation}
                                    onPress={this.onPressScroll}
                                />
                            </View>
                        </View>
                    </View>
                    <LinearGradient
                        start={[0.0, 0.0]}
                        end={[1.0, 1.0]}
                        colors={[Colors.sunshine, Colors.accentDeepsea]}
                        style={[
                            Layout.projectContainer,
                            { backgroundColor: Colors.tintColor },
                        ]}
                    >
                        {/* <View
                        style={[
                            Layout.projectContainer,
                            { backgroundColor: Colors.tintColor },
                        ]}
                    > */}
                        <View style={Layout.columnContainer}>
                            <View style={Layout.columnThree}>
                                <Text
                                    style={[
                                        TextStyles.Heading3,
                                        {
                                            paddingBottom: Layout.paddingMedium,
                                        },
                                    ]}
                                >
                                    Restaurant Workers’ Community Foundation -
                                    COVID-19 Crisis Relief Fund
                                </Text>
                                <Text style={TextStyles.Paragraph}>
                                    Restaurant Workers’ Community Foundation
                                    works towards equity, fair wages, and a
                                    healthy work environment for the restaurant
                                    industry. Its COVID-19 Crisis Relief Fund
                                    puts 100% of donations towards direct relief
                                    for individual restaurant workers, zero
                                    interest loans for restaurants to get back
                                    up and running, and towards supporting other
                                    nonprofits serving restaurant workers in
                                    crisis.
                                </Text>
                                <Button
                                    onPress={() =>
                                        Linking.openURL(
                                            'https://www.restaurantworkerscf.org/news/2020/3/15/restaurant-workers-community-foundation-announces-the-formation-of-restaurant-workers-covid19-crisis-relief-fund'
                                        )
                                    }
                                    buttonStyle={[
                                        Buttons.buttonMini,
                                        {
                                            minWidth: 160,
                                            marginTop: Layout.paddingLarge,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    title={'Donate'}
                                />
                            </View>
                            <View style={Layout.columnThree}>
                                <Text
                                    style={[
                                        TextStyles.Heading3,
                                        {
                                            paddingBottom: Layout.paddingMedium,
                                        },
                                    ]}
                                >
                                    The James Beard Foundation Food & Beverage
                                    Relief Fund
                                </Text>
                                <Text style={TextStyles.Paragraph}>
                                    To help bring swift economic relief to the
                                    restaurant industry, the James Beard
                                    Foundation launched a fund that will be
                                    gathering support from corporate,
                                    foundation, and individual donors to provide
                                    micro-grants to independent food and
                                    beverage businesses in need.
                                </Text>
                                <Button
                                    onPress={() =>
                                        Linking.openURL(
                                            'https://www.jamesbeard.org/relief'
                                        )
                                    }
                                    buttonStyle={[
                                        Buttons.buttonMini,
                                        {
                                            minWidth: 160,
                                            marginTop: Layout.paddingLarge,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    title={'Donate'}
                                />
                            </View>
                            <View style={Layout.columnThree}>
                                <Text
                                    style={[
                                        TextStyles.Heading3,
                                        {
                                            paddingBottom: Layout.paddingMedium,
                                        },
                                    ]}
                                >
                                    The Independent Restaurants Coalition
                                </Text>
                                <Text style={TextStyles.Paragraph}>
                                    The Independent Restaurants Coalition (IRC)
                                    was formed by a group of chefs to save local
                                    restaurants impacted by COVID-19. By
                                    supporting the IRC, you’ll be a part of
                                    advancing critical legislation that can help
                                    save 11 million restaurant employees and the
                                    hundreds of millions who support restaurant
                                    delivery and supply chains.
                                </Text>
                                <Button
                                    onPress={() =>
                                        Linking.openURL(
                                            'https://www.saverestaurants.co/'
                                        )
                                    }
                                    buttonStyle={[
                                        Buttons.buttonMini,
                                        {
                                            minWidth: 160,
                                            marginTop: Layout.paddingLarge,
                                        },
                                    ]}
                                    textColor={Colors.tintColor}
                                    title={'Get involved'}
                                />
                            </View>
                        </View>
                        {/* </View> */}
                    </LinearGradient>

                    <Footer navigation={this.props.navigation} />
                </ScrollView>
            </View>
        )
    }
}
