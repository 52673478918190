import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { createStackNavigator } from 'react-navigation-stack'
import { DrawerActions, createDrawerNavigator } from 'react-navigation-drawer'
import { Layout, Buttons, Colors, TextStyles } from '../constants'
import AuthLoadingScreen from '../screens/auth/AuthLoadingScreen'
import AuthHomeScreen from '../screens/auth/AuthHomeScreen'
import ContactScreen from '../screens/global/ContactScreen'
import UserAgreementScreen from '../screens/global/UserAgreementScreen'
//import MetaProjectScreen from '../screens/private/MetaProjectScreen'
import RequestInfoScreen from '../screens/global/RequestInfoScreen'
import RequestConfirmationScreen from '../screens/global/RequestConfirmationScreen'
import TopNavHeaderTitle from '../components/TopNavHeaderTitle'
import DrawerNavigation from '../components/DrawerNavigation'
import ButtonIcon from '../components/ButtonIcon'

const DrawerConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/drawer-navigator.html
     *
     **/
    drawerWidth: Layout.isTabletDevice
        ? Layout.SCREEN_WIDTH
        : Layout.SCREEN_WIDTH * 0.5,
    drawerPosition: 'right',
    drawerBackgroundColor: Colors.accentYellow,
    overlayColor: Colors.darkBackground,

    drawerLockMode: 'locked-closed',

    contentComponent: (props) => <DrawerNavigation {...props} />,
}

const StackConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/stack-navigator.html#stacknavigatorconfig
     *
     **/

    initialRouteName: 'PrivateHome',
    lazy: true /* defaults to true */,
    unmountInactiveScreens: false /* defaults to false */,

    headerMode: Layout.isTabletDevice ? 'screen' : 'float', // 'float' | 'screen' | 'none'
    headerTransitionPreset: 'fade-in-place', // float is enabled; 'uikit' | 'fade-in-place'

    defaultNavigationOptions: ({ navigation }) => ({
        cardShadowEnabled: false,

        headerBackTitle: null,
        headerPressColorAndroid: Colors.tintColor,
        headerTransparent: true,
        headerStyle: {
            boxShadow: 'none', // for web
            //elevation: 0, //for android
            shadowOpacity: 0, //for ios
            borderBottomWidth: 0, //for ios
            paddingVertical: Layout.paddingSmall,
            height: Layout.isTabletDevice
                ? Layout.topNavHeightMobile
                : Layout.topNavHeightDesktop,
            backgroundColor: navigation.getParam('active', false)
                ? 'transparent'
                : Colors.gray0,
        },

        headerLeft: (
            <View
                style={{
                    flex: 1,
                    paddingVertical: Layout.paddingMedium,
                    paddingHorizontal: Layout.isTabletDevice
                        ? Layout.paddingSmall
                        : Layout.paddingLarge,
                }}
            >
                <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() => navigation.navigate('PrivateHome')}
                >
                    <Text
                        numberOfLines={1}
                        style={[
                            Layout.isTabletDevice
                                ? TextStyles.Logotype
                                : TextStyles.Logotype,
                            {
                                color: navigation.getParam('active', false)
                                    ? Colors.inverseText
                                    : Colors.tintColor,
                                paddingLeft: Layout.paddingSmall,
                            },
                        ]}
                    >
                        Foodist
                    </Text>
                </TouchableOpacity>
            </View>
        ),

        headerRight: (
            <View
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    paddingRight: Layout.isTabletDevice
                        ? Layout.paddingSmall
                        : Layout.paddingLarge,
                }}
            >
                {Layout.isTabletDevice ? (
                    <ButtonIcon
                        onPress={() =>
                            navigation.dispatch(DrawerActions.toggleDrawer())
                        }
                        iconName={'menu'}
                        buttonStyle={
                            navigation.getParam('active', false)
                                ? Buttons.menuButtonTransparentBG
                                : Buttons.menuButton
                        }
                    />
                ) : (
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    paddingVertical: Layout.paddingMedium,
                                    paddingRight: Layout.paddingLarge,
                                    color: navigation.getParam('active', false)
                                        ? Colors.inverseText
                                        : Colors.tintColor,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => {
                                navigation.navigate('MetaProject')
                            }}
                        >
                            Our vision
                        </Text>
                        <Text
                            style={[
                                TextStyles.Overline,
                                {
                                    paddingVertical: Layout.paddingMedium,
                                    color: navigation.getParam('active', false)
                                        ? Colors.inverseText
                                        : Colors.tintColor,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => navigation.navigate('RequestInfo')}
                        >
                            Follow
                        </Text>
                    </View>
                )}
            </View>
        ),
    }),
}

const PrivateStack = createStackNavigator(
    {
        AuthLoading: {
            screen: AuthLoadingScreen,
        },

        PrivateHome: {
            screen: AuthHomeScreen,
        },

        // Contact: {
        //     screen: ContactScreen,
        // },

        // MetaProject: {
        //     screen: MetaProjectScreen,
        //     navigationOptions: ({ navigation }) => ({
        //         headerLeft: (
        //             <View
        //                 style={{
        //                     flex: 1,
        //                     paddingVertical: Layout.paddingMedium,
        //                     paddingHorizontal: Layout.isTabletDevice
        //                         ? Layout.paddingSmall
        //                         : Layout.paddingLarge,
        //                 }}
        //             >
        //                 <ButtonIcon
        //                     onPress={() => navigation.goBack()}
        //                     iconName={'arrow-left'}
        //                     iconColor={Colors.tintColor}
        //                     buttonStyle={Buttons.menuBackButtonIcon}
        //                 />
        //             </View>
        //         ),
        //         headerTitle: <TopNavHeaderTitle headerTitle={'Foodist'} />,
        //         headerRight: (
        //             <View
        //                 style={{
        //                     flex: 1,
        //                     paddingVertical: Layout.paddingMedium,
        //                     paddingHorizontal: Layout.isTabletDevice
        //                         ? Layout.paddingSmall
        //                         : Layout.paddingLarge,
        //                 }}
        //             >
        //                 <ButtonIcon
        //                     onPress={() =>
        //                         navigation.dispatch(
        //                             DrawerActions.toggleDrawer()
        //                         )
        //                     }
        //                     iconName={'menu'}
        //                     buttonStyle={Buttons.menuButton}
        //                 />
        //             </View>
        //         ),
        //     }),
        // },

        UserAgreement: {
            screen: UserAgreementScreen,
            navigationOptions: ({ navigation }) => ({
                headerLeft: (
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: Layout.paddingMedium,
                            paddingHorizontal: Layout.isTabletDevice
                                ? Layout.paddingSmall
                                : Layout.paddingLarge,
                        }}
                    >
                        <ButtonIcon
                            onPress={() => navigation.goBack()}
                            iconName={'arrow-left'}
                            iconColor={Colors.tintColor}
                            buttonStyle={Buttons.menuBackButtonIcon}
                        />
                    </View>
                ),
                headerRight: (
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: Layout.paddingMedium,
                            paddingHorizontal: Layout.isTabletDevice
                                ? Layout.paddingSmall
                                : Layout.paddingLarge,
                        }}
                    >
                        <ButtonIcon
                            onPress={() =>
                                navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                            }
                            iconName={'menu'}
                            buttonStyle={Buttons.menuButton}
                        />
                    </View>
                ),
            }),
        },

        RequestInfo: {
            screen: RequestInfoScreen,
            navigationOptions: ({ navigation }) => ({
                headerLeft: (
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: Layout.paddingMedium,
                            paddingHorizontal: Layout.isTabletDevice
                                ? Layout.paddingSmall
                                : Layout.paddingLarge,
                        }}
                    >
                        <ButtonIcon
                            onPress={() => navigation.goBack()}
                            iconName={'arrow-left'}
                            iconColor={Colors.tintColor}
                            buttonStyle={Buttons.menuBackButtonIcon}
                        />
                    </View>
                ),
                // headerTitle: (
                //     <TopNavHeaderTitle headerTitle={'Email sign up'} />
                // ),
                headerRight: (
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: Layout.paddingMedium,
                            paddingHorizontal: Layout.isTabletDevice
                                ? Layout.paddingSmall
                                : Layout.paddingLarge,
                        }}
                    >
                        <ButtonIcon
                            onPress={() =>
                                navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                            }
                            iconName={'menu'}
                            buttonStyle={Buttons.menuButton}
                        />
                    </View>
                ),
            }),
        },

        RequestConfirmation: {
            screen: RequestConfirmationScreen,
            navigationOptions: ({ navigation }) => ({
                headerRight: (
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: Layout.paddingMedium,
                            paddingHorizontal: Layout.isTabletDevice
                                ? Layout.paddingSmall
                                : Layout.paddingLarge,
                        }}
                    >
                        <ButtonIcon
                            onPress={() =>
                                navigation.dispatch(
                                    DrawerActions.toggleDrawer()
                                )
                            }
                            iconName={'menu'}
                            buttonStyle={Buttons.menuButton}
                        />
                    </View>
                ),
            }),
        },
    },
    StackConfig
)

export default createDrawerNavigator(
    {
        PrivateStack,
    },
    DrawerConfig
)
